<template>
    <ConfirmDialog ref="confirmDialog"/>
    <SmartTable :table-name="tableName" class="review-table" :columns="columns" :items="items" :infinity="true">
        <template #name="{ item }">
            <div class="review-table__name-wrapper">
                <CIcon v-if="checkIsTaskOpen(item.id)" name="cilChevronBottomSolid"
                       class="review-table__chevron review-table__chevron_active"
                       @click="toggleTaskAccordion(item.id)"/>
                <CIcon v-else name="cilChevronBottomSolid" class="review-table__chevron"
                       @click="toggleTaskAccordion(item.id)"/>
                <div class="review-table__name">
                    <span class="text-overflow-ellipsis" :title="item.name">
                        {{ item.name }}
                    </span>
                </div>
                <CIcon v-if="item.is_main" name="cilCrownLabel"/>
            </div>
        </template>

        <template #document_name="{ item }">
            <div class="review-table__file">
                <span class="text-overflow-ellipsis" :title="item.document_name"
                      @click="toggleDocumentPreviewer(item.template_id)">
                    {{ item.document_name }}
                </span>
            </div>
        </template>

        <template #task_action="{ item }">
            {{ displayTaskAction(item) }}
        </template>

        <template #assign_date="{ item }">
            <CInputDate v-if="!item.is_indefinite" v-model="item.start_date" :is-required="isPrimary"
                        :disabled="isSecondary"
                        @update:model-value="onTaskUpdate(formatDate($event), item, 'start_date')"/>
            <span v-else class="d-flex align-items-center" style="width: 170px; height: 38px">N/A</span>
        </template>

        <template #due_date="{ item }">
            <div class="review-table__due-date">
                <CInputDate v-if="!item.is_indefinite" v-model="item.due_date" :is-required="isPrimary"
                            :disabled="isSecondary"
                            @update:model-value="onTaskUpdate(formatDate($event), item, 'due_date')"/>
                <span v-else class="d-flex align-items-center" style="width: 170px; height: 38px">N/A</span>
                <CIcon name="cilTrash" @click="onTaskDelete(item)"/>
            </div>
        </template>

        <template #details_accordion="{ item }">
            <CCollapse :visible="checkIsTaskOpen(item.id)">
                <div v-if="item.priority_type === 1" class="review-table__accordion">
                    <textarea v-model="item.description" placeholder="Description" maxlength="1000"
                              @input="onTaskUpdate($event.target.value, item, 'description')"/>
                </div>

                <div v-else class="review-table__accordion review-table__accordion_recurrence">
                    <textarea v-model="item.description" placeholder="Description" maxlength="1000"
                              @input="onTaskUpdate($event.target.value, item, 'description')"/>
                    <div>
                        <DueDateSelector :assign_date="item.start_date" :due_date="item.due_date"
                                         :is_indefinite="item.is_indefinite" :hide-dates="true"
                                         :settings="item.task_recurrence" file="Review Contract Package"
                                         :permissions="['recurrence', 'start_date', 'due_date']"
                                         @saved="onTaskUpdate($event, item, 'task_recurrence')"/>

                        <div class="form-check" style="margin-top: 18px">
                            <input id="indefiniteCheck" class="form-check-input" type="checkbox"
                                   :value="item.is_indefinite" :checked="item.is_indefinite"
                                   @change="onTaskUpdate(!item.is_indefinite, item, 'is_indefinite')"/>
                            <label class="form-check-label" for="indefiniteCheck"> Without assigned date </label>
                        </div>
                    </div>
                </div>
            </CCollapse>
        </template>
    </SmartTable>
    <DocumentPreviewer :visible="documentPreviewer.visible" type="templates" :type-id="documentPreviewer.typeId"
                       :v2="true" @closed="toggleDocumentPreviewer(null)"/>
</template>

<script>
import CInputDate from '@/components/CInputDate.vue';
import SmartTable from '@/components/SmartTable/index.vue';
import moment from 'moment/moment';
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import DocumentPreviewer from '@/components/Documents/DocumentPreviewer.vue';

export default {
    name: 'ReviewContractTable',
    components: {DueDateSelector, ConfirmDialog, CInputDate, SmartTable, DocumentPreviewer},
    props: {
        columns: {
            type: Object,
            default: () => {
            },
        },
        items: {
            type: Array,
            default: () => [],
        },
        tableName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            openedTasks: [],
            documentPreviewer: {
                visible: false,
                typeId: null,
            },
        };
    },
    computed: {
        isPrimary() {
            return this.tableName === 'review-primary';
        },
        isSecondary() {
            return this.tableName === 'review-secondary';
        }
    },
    methods: {
        displayTaskAction(task) {
            if (task.required_action === 'Acknowledgement of Receipt Required') return 'Ack. of Receipt';

            return task.required_action;
        },
        onTaskDelete(task) {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Delete template',
                    info: `Are you sure you want to delete template ${task.name}?`,
                    confirmText: 'Delete',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.$emit('taskDelete', task);
                });
        },
        formatDate(date) {
            const currentDate = new Date();

            return moment(date)
                .set({h: currentDate.getHours(), m: currentDate.getMinutes()})
                .format('YYYY-MM-DD HH:mm:ss');
        },
        getTaskById(taskId) {
            if (!this.items.length) return false;

            return this.items.find((task) => task.id === taskId);
        },
        onTaskUpdate(data, task, propName) {
            const selectedTask = this.getTaskById(task.id);
            this.$emit('changesCommited');

            selectedTask[propName] = data;
        },
        checkIsTaskOpen(taskId) {
            return this.openedTasks.includes(taskId);
        },
        toggleTaskAccordion(id) {
            const taskAlreadyOpened = this.checkIsTaskOpen(id);

            if (taskAlreadyOpened) {
                this.openedTasks = this.openedTasks.filter((x) => x !== id);
            } else {
                this.openedTasks.push(id);
            }
        },
        toggleDocumentPreviewer(templateId) {
            this.documentPreviewer.typeId = templateId;
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
        },
    },
};
</script>

<style lang="scss">
.review-table {
    & .c-input-date {
        width: 170px;
        margin: 0 !important;
    }

    & .infinityScroll {
        max-height: 1000px !important;
    }

    &__name {
        width: 230px;

        &-wrapper {
            width: 300px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            gap: 10px;

            & svg {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }

    &__chevron {
        transition: 0.3s;

        &_active {
            transform: rotate(180deg);
        }
    }

    &__file {
        color: #0068ad;
        font-size: 14px;
        text-decoration: underline;
        width: 250px;
        cursor: pointer;
    }

    &__due-date {
        display: flex;
        align-items: center;
        padding-right: 10px;
        width: 100%;
        justify-content: space-between;
        gap: 32px;

        & svg {
            color: red;
            cursor: pointer;
        }
    }

    &__accordion {
        border-bottom: 1px solid #dee4e8;
        margin-bottom: 24px;
        margin-top: 24px;
        padding-bottom: 16px;
        padding-left: 5px;
        display: flex;
        gap: 24px;
        align-items: flex-end;

        &_recurrence {
            align-items: flex-start !important;

            & .due-date {
                width: 520px;
            }
        }

        & textarea {
            margin: 0;
            width: 522px;
            border-radius: 8px;
            border: 1px solid #9fafbc;
            padding: 12px 16px;
            color: #677a89;
            font-size: 14px;
            outline: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus {
                color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
                background-color: var(--cui-input-focus-bg, #fff);
                border-color: var(--cui-input-focus-border-color, #998fed);
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
            }
        }
    }
}
</style>
